import { http } from "../../http";
import {
  IFetchSpotsReservationsRequest,
  IFetchSpotsReservationsContract,
  IFetchSpotsReservationsResponse,
} from "./types";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/v2/repo/errors";

export class FetchSpotsReservationsError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchSpotsReservations");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}
export async function fetchSpotsReservations(
  request: IFetchSpotsReservationsRequest
): Promise<IFetchSpotsReservationsResponse> {
  let response: AxiosResponse<IFetchSpotsReservationsContract>;

  try {
    response = await http.get(
      `api/v3/outlets/${request.outletId}/tables/reservations`,
      {
        params: {
          starting_at: request.startingAt,
          ending_at: request.endingAt,
        },
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchSpotsReservationsError(error);
  }

  return response.data.data.length
    ? response.data.data.map((spot) => ({
        id: spot.id,
        spotId: spot.table_id,
        startsAt: new Date(spot.starts_at),
        finishesAt: new Date(spot.finishes_at),
        reservedFor: {
          name: spot.reserved_for.name,
          phoneNumber: spot.reserved_for.phone_number,
        },
        reference: spot.reference,
      }))
    : [];
}
