import {
  IFetchReservationsWithoutSpotBodyParams,
  IFetchReservationsWithoutSpotContract,
  IFetchReservationsWithoutSpotRequest,
  IFetchReservationsWithoutSpotResponse,
} from "./types";
import { http } from "../../http";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "../../errors";

export class FetchReservationsWithoutSpotError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchReservationsWithoutSpotError");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function fetchReservationsWithoutSpot(
  request: IFetchReservationsWithoutSpotRequest
): Promise<IFetchReservationsWithoutSpotResponse> {
  let response: AxiosResponse<IFetchReservationsWithoutSpotContract>;

  try {
    const params: IFetchReservationsWithoutSpotBodyParams = {
      event_start_at_from: request.startDate.toISOString(),
      event_start_at_to: request.endDate.toISOString(),
    };

    response = await http.get(
      `/event-booking/outlet/${request.outletId}/reservations/get-without-table-allocation`,
      { params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchReservationsWithoutSpotError(e);
  }

  return response.data.data.length
    ? response.data.data.map((reservation) => ({
        id: reservation.id,
        reference: reservation.reference,
        notes: reservation.special_requests,
        attendee: {
          email: reservation.attendee.email,
          name: reservation.attendee.name,
          phoneNumber: reservation.attendee.phone_number,
        },
        event: {
          title: reservation.ticket.event.title,
          startsAt: new Date(reservation.ticket.event.starts_at),
          finishesAt: new Date(reservation.ticket.event.finishes_at),
        },
        ticket: {
          name: reservation.ticket.name,
          price: reservation.price,
        },
      }))
    : [];
}
