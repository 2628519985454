import { IDeallocateSpotRequest } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class DeallocateSpotError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: DeallocateSpot");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function deallocateSpot(
  request: IDeallocateSpotRequest
): Promise<boolean> {
  let response: AxiosResponse;

  try {
    response = await http.delete(
      `api/v3/outlets/${request.outletId}/tables/reservations/${request.reservationId}`
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const customError = e?.response?.data?.error;
    if (customError) {
      e.errorCode = customError?.code;
    }

    throw new DeallocateSpotError(e);
  }

  return Boolean(response);
}
