















































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  PropType,
  ref,
  computed,
  watch,
  onBeforeUnmount,
} from "@vue/composition-api";
import {
  AtomIcon,
  AtomDate,
  AtomText,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { SpotsOccupancyOccupy } from "../occupy";
import { ISpotsOccupancyReservation } from "../types";
import { ReservationsAssignSpot } from "../../reservations/assign-spot";
import { moveSpotReservation } from "@/v2/repo/spots-occupancy/move-spot-reservation";
import { report } from "@chatfood/bug-reporter";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { t } from "@/i18n";

const css = bemBuilder("spots-occupancy-spot");

dayjs.extend(isToday);

export default defineComponent({
  name: "SpotsOccupancySpot",
  components: {
    AtomIcon,
    AtomText,
    AtomDate,
    SpotsOccupancyOccupy,
    ReservationsAssignSpot,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    spotId: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    seats: {
      type: Number,
      required: true,
    },
    occupiedAt: {
      type: Date,
      default: null,
    },
    reservations: {
      type: Array as PropType<Array<ISpotsOccupancyReservation>>,
      default: () => [],
    },
    dayFilter: {
      type: Date,
      required: true,
    },
  },
  emits: ["update"],
  setup(props, { emit }) {
    const minutesAgo = ref(0);
    const intervalId = ref();

    const showOccupyModal = ref(false);
    const isToday = computed(() => dayjs(props.dayFilter).isToday());

    const allowActions = computed(() => {
      return isToday.value || props.reservations.length;
    });

    function calcMinutes() {
      minutesAgo.value = dayjs().diff(dayjs(props.occupiedAt), "minutes");

      intervalId.value = setInterval(() => {
        minutesAgo.value = minutesAgo.value + 1;
      }, 60000);
    }

    watch(
      () => props.occupiedAt,
      () => {
        if (props.occupiedAt) {
          calcMinutes();
        }
      },
      { immediate: true }
    );

    onBeforeUnmount(() => {
      if (intervalId.value) clearInterval(intervalId.value);
    });

    const currentOccupation = computed(() => {
      const hours = Math.floor(minutesAgo.value / 60);
      const minutes = minutesAgo.value % 60;
      const currentHours = t("module.spots_occupancy.spot.x_hours", [hours]);
      const currentMinutes = t("module.spots_occupancy.spot.x_minutes", [
        minutes.toString().padStart(2, "0"),
      ]);
      return minutesAgo.value < 60
        ? currentMinutes
        : `${currentHours} ${currentMinutes}`;
    });

    function openOccupyModal() {
      if (!allowActions.value) return;

      showOccupyModal.value = true;
    }

    function onUpdate() {
      emit("update");
      showOccupyModal.value = false;
    }

    const showMoveSpotModal = ref(false);
    const reservationToMove = ref<ISpotsOccupancyReservation | null>(null);

    function onMoveSpot(reservation: ISpotsOccupancyReservation) {
      reservationToMove.value = reservation;
      showOccupyModal.value = false;
      showMoveSpotModal.value = true;
    }

    async function onMovingSpot(spotId: string) {
      if (!reservationToMove.value?.id) return;

      try {
        await moveSpotReservation({
          outletId: props.outletId,
          reservationId: reservationToMove.value?.id,
          spotId,
        });

        new Toast().create({
          type: "success",
          text: t("module.spots_occupancy.spot.success_desc_moved"),
        });
      } catch (e: any) {
        report(e);

        const mapErrors: Record<string, string> = {
          event_booking_reservation_already_allocated_to_table: t(
            "module.spots_occupancy.spot.error_already_allocated_to_table"
          ),
          event_booking_table_already_reserved: t(
            "module.spots_occupancy.spot.error_table_already_reserved"
          ),
          event_booking_invalid_reservation_state: t(
            "module.spots_occupancy.spot.error_invalid_reservation_state"
          ),
        };

        new Toast().create({
          type: "error",
          text:
            mapErrors[e?.errors?.errorCode] ??
            t("module.spots_occupancy.spot.error_generic"),
        });
      } finally {
        showMoveSpotModal.value = false;
      }
    }

    function closeAssignSpotModal() {
      reservationToMove.value = null;
      showMoveSpotModal.value = false;
    }

    function movedSpot() {
      emit("update");
      closeAssignSpotModal();
    }

    return {
      t,
      css,
      isToday,
      onUpdate,
      minutesAgo,
      allowActions,
      showOccupyModal,
      openOccupyModal,
      currentOccupation,
      showMoveSpotModal,
      onMoveSpot,
      onMovingSpot,
      reservationToMove,
      closeAssignSpotModal,
      movedSpot,
      AtomTextTypeEnum,
    };
  },
});
