








































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import {
  AtomModal,
  AtomIcon,
  AtomDate,
  AtomButton,
  AtomButtonTypeEnum,
  AtomButtonSizeEnum,
  AtomText,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import { markOccupiedSpot } from "@/v2/repo/spots-occupancy/mark-occupied-spot";
import { markAvailableSpot } from "@/v2/repo/spots-occupancy/mark-available-spot";
import { deallocateSpot } from "@/v2/repo/spots-occupancy/deallocate-table";
import { report } from "@chatfood/bug-reporter";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { ISpotsOccupancyReservation } from "../types";
import { phoneBeautifier } from "@chatfood/core-utils";
import { t } from "@/i18n";

const css = bemBuilder("spots-occupancy-occupy");

export default defineComponent({
  name: "SpotsOccupancyOccupy",
  components: {
    AtomModal,
    AtomText,
    AtomDate,
    AtomIcon,
    AtomButton,
  },
  props: {
    outletId: {
      type: String,
      required: true,
    },
    spotId: {
      type: String,
      required: true,
    },
    spotName: {
      type: String,
      required: true,
    },
    seats: {
      type: Number,
      required: true,
    },
    isOccupied: {
      type: Boolean,
      requited: true,
    },
    reservations: {
      type: Array as PropType<Array<ISpotsOccupancyReservation>>,
      default: () => [],
    },
    blocksOccupancy: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["close", "update", "move-spot"],
  setup(props, { emit }) {
    const isUpdating = ref(false);

    const ctaLabel = computed(() => {
      return props.isOccupied
        ? t("module.spots_occupancy.occupy.free_table")
        : t("module.spots_occupancy.occupy.occupy_table");
    });

    function phoneFormatted(phone: string) {
      return phoneBeautifier(phone);
    }

    async function updateOccupancy() {
      isUpdating.value = true;

      const request = {
        outletId: props.outletId,
        spotId: props.spotId,
      };

      try {
        props.isOccupied
          ? await markAvailableSpot(request)
          : await markOccupiedSpot(request);

        emit("update");
      } catch (error) {
        report(error);
        generalErrorToast();
      } finally {
        isUpdating.value = false;
      }
    }

    async function unassignBooking(reservationId: string) {
      const request = {
        outletId: props.outletId,
        spotId: props.spotId,
        reservationId: reservationId,
      };

      try {
        await deallocateSpot(request);
        onClose();
      } catch (error) {
        report(error);
        generalErrorToast();
      }
    }

    function onMovingSpot(reservation: ISpotsOccupancyReservation) {
      emit("move-spot", reservation);
    }

    function onClose() {
      emit("close");
    }

    return {
      t,
      css,
      onClose,
      ctaLabel,
      updateOccupancy,
      isUpdating,
      onMovingSpot,
      phoneFormatted,
      AtomTextTypeEnum,
      AtomTextColorEnum,
      AtomButtonTypeEnum,
      AtomButtonSizeEnum,
      unassignBooking,
    };
  },
});
