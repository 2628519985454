


































































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType } from "@vue/composition-api";
import {
  AtomIcon,
  AtomDate,
  AtomMoney,
  AtomText,
  AtomTextTagEnum,
  AtomTextTypeEnum,
  AtomTextColorEnum,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { ReservationsAssignSpot } from "@/v2/module/reservations/assign-spot";
import { IFetchReservationsWithoutSpotResponse } from "@/v2/repo/reservations/fetch-reservations-without-spot";
import { allocateSpot } from "@/v2/repo/spots-occupancy/allocate-spot";
import { phoneBeautifier } from "@chatfood/core-utils";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";

const css = bemBuilder("spots-occupancy-unassigned-bookings");

export default defineComponent({
  name: "SpotsOccupancyUnassignedBookings",
  components: {
    AtomIcon,
    AtomDate,
    AtomMoney,
    AtomText,
    ReservationsAssignSpot,
  },
  props: {
    outletId: {
      type: String,
      default: "",
    },
    reservations: {
      type: Array as PropType<IFetchReservationsWithoutSpotResponse>,
      default: () => [],
    },
  },
  setup(props) {
    const reservationSelected = ref<
      IFetchReservationsWithoutSpotResponse[0] | null
    >(null);
    const showAssignSpotModal = ref(false);
    const moreDetail = ref(false);

    function toggleAssignSpotModal(state: boolean) {
      if (!state) moreDetail.value = false;

      showAssignSpotModal.value = state;
    }

    async function assignSpot(booking: any) {
      await (reservationSelected.value = booking);
      toggleAssignSpotModal(true);
    }

    async function onAssignSpot(spotId: string) {
      if (!reservationSelected.value) return;

      try {
        await allocateSpot({
          outletId: props.outletId,
          reservationId: reservationSelected.value.id,
          spotId,
        });

        new Toast().create({
          type: "success",
          text: t(
            "module.spots_occupancy.unassigned_bookings.success_desc_assigned"
          ),
        });
      } catch (e: any) {
        report(e);

        const mapErrors: Record<string, string> = {
          event_booking_reservation_already_allocated_to_table: t(
            "module.spots_occupancy.unassigned_bookings.error_already_allocated_to_table"
          ),
          event_booking_table_already_reserved: t(
            "module.spots_occupancy.unassigned_bookings.error_table_already_reserved"
          ),
          event_booking_invalid_reservation_state: t(
            "module.spots_occupancy.unassigned_bookings.error_invalid_reservation_state"
          ),
        };

        new Toast().create({
          type: "error",
          text:
            mapErrors[e?.errors?.errorCode] ??
            t("module.spots_occupancy.unassigned_bookings.error_generic"),
        });
      } finally {
        reservationSelected.value = null;
        toggleAssignSpotModal(false);
      }
    }

    return {
      t,
      css,
      reservationSelected,
      moreDetail,
      assignSpot,
      onAssignSpot,
      phoneBeautifier,
      showAssignSpotModal,
      toggleAssignSpotModal,
      AtomTextTagEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
