





































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import {
  AtomIcon,
  AtomText,
  AtomSwitcher,
  AtomTextTypeEnum,
  MolSearchBox,
} from "@/v2/new-design-system";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import { t } from "@/i18n";

const css = bemBuilder("spots-occupancy-filter");

dayjs.extend(isToday);

export default defineComponent({
  name: "SpotsOccupancyFilter",
  components: {
    AtomIcon,
    AtomText,
    AtomSwitcher,
    MolSearchBox,
  },
  props: {
    day: {
      type: Date,
      required: true,
    },
    onlyAvailable: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["change-day", "only-available", "search"],
  setup(props, { emit }) {
    const dayFormatted = computed(() =>
      dayjs(props.day).calendar(null, {
        sameDay: `[${t("module.spots_occupancy.filter.today")}], DD MMM`,
        nextDay: `[${t("module.spots_occupancy.filter.tomorrow")}], DD MMM`,
        nextWeek: "dddd, DD MMM",
        lastDay: `[${t("module.spots_occupancy.filter.yesterday")}], DD MMM`,
        lastWeek: "dddd, DD MMM",
        sameElse: "dddd, DD MMM",
      })
    );

    const isToday = computed(() => dayjs(props.day).isToday());

    function changeDayFilter(setDay: number) {
      if (isToday.value && setDay === -1) return;

      emit("change-day", setDay);
    }

    function setOnlyAvailable() {
      emit("only-available");
    }

    function onSearch(value: string) {
      emit("search", value.trim());
    }

    return {
      t,
      css,
      isToday,
      onSearch,
      dayFormatted,
      changeDayFilter,
      setOnlyAvailable,
      AtomTextTypeEnum,
    };
  },
});
