










































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  defineComponent,
  ref,
  watch,
  computed,
  onBeforeUnmount,
} from "@vue/composition-api";
import { OutletSwitcher } from "@/v2/components/outlet-switcher";
import {
  AtomText,
  AtomTextTagEnum,
  AtomTextTypeEnum,
  AtomTextColorEnum,
  AtomLoading,
  OrgHeaderInfo,
  OrgCollapseGroup,
} from "@/v2/new-design-system";
import { SpotsOccupancyFilter } from "../filter";
import { SpotsOccupancySpot } from "../spot";
import { SpotsOccupancyUnassignedBookings } from "../unassigned-bookings";
import {
  fetchSpots,
  IFetchSpotsResponse,
} from "@/v2/repo/spots-management/fetch-spots";
import {
  fetchSpotsReservations,
  IFetchSpotsReservationsResponse,
} from "@/v2/repo/spots-occupancy/fetch-spots-reservations";
import {
  fetchSpotsOccupied,
  IFetchSpotsOccupiedResponse,
} from "@/v2/repo/spots-occupancy/fetch-spots-occupied";
import {
  fetchReservationsWithoutSpot,
  IFetchReservationsWithoutSpotResponse,
} from "@/v2/repo/reservations/fetch-reservations-without-spot";
import { ISpotsOccupancyGroup, ISpotsOccupancySpot } from "../types";
import { removeSpecialCharacters } from "@chatfood/core-utils";
import {
  listenFromSpotOccupancy,
  unlistenFromSpotOccupancy,
} from "@/v2/core/broadcasting";
import { report } from "@chatfood/bug-reporter";
import { t } from "@/i18n";
import dayjs from "dayjs";

const css = bemBuilder("spots-occupancy-main");

export default defineComponent({
  name: "SpotsOccupancyMain",
  components: {
    AtomText,
    AtomLoading,
    OrgHeaderInfo,
    OrgCollapseGroup,
    SpotsOccupancyFilter,
    SpotsOccupancySpot,
    SpotsOccupancyUnassignedBookings,
    OutletSwitcher,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isLoading = ref(true);
    const collapsedAll = ref(false);
    const onlyAvailable = ref(false);

    const searchQuery = ref("");

    const spotsGroup = ref<Array<ISpotsOccupancyGroup>>([]);

    const dayFilter = ref(dayjs());
    const startingAtFilter = computed(() =>
      dayjs(dayFilter.value).startOf("day").toDate()
    );
    const endingAtFilter = computed(() =>
      dayjs(dayFilter.value).endOf("day").toDate()
    );

    const currentSpotsGroup = computed(() => {
      let filteredReservations = spotsReservations.value;

      if (searchQuery.value) {
        filteredReservations = filteredReservations.filter((reservation) => {
          const queryLowerCase = sanitizedString(searchQuery.value);
          const referenceLowerCase = sanitizedString(reservation.reference);
          const nameLowerCase = sanitizedString(reservation.reservedFor.name);
          const phoneNumberLowerCase = sanitizedString(
            reservation.reservedFor.phoneNumber
          );

          return (
            nameLowerCase.includes(queryLowerCase) ||
            phoneNumberLowerCase.includes(queryLowerCase) ||
            referenceLowerCase.includes(queryLowerCase)
          );
        });
      }

      const groupedSpots = spotsResponse.value.reduce((previous, current) => {
        const isCurrentGroupListed = previous.find(
          (spot) => spot.group === current.groupingLabel
        );

        if (!isCurrentGroupListed) {
          previous.push({
            group: current.groupingLabel,
            spots: [],
          });
        }

        const group = previous.find(
          (spot) => spot.group === current.groupingLabel
        );

        if (!group) {
          return previous;
        }

        const reservations = filteredReservations.filter(
          (reservation) => reservation.spotId === current.id
        );

        let spotObj: ISpotsOccupancySpot | null = {
          id: current.id,
          name: current.name,
          seats: current.seats ?? 0,
          occupiedAt: findOccupiedAt(current.id),
          reservations,
        };

        if (
          (onlyAvailable.value && spotObj?.occupiedAt) ||
          (searchQuery.value && !reservations.length)
        ) {
          spotObj = null;
        }

        if (spotObj) {
          group.spots.push(spotObj);
        }

        return previous;
      }, [] as Array<ISpotsOccupancyGroup>);

      return groupedSpots.filter((group) => group.spots.length > 0);
    });

    function sanitizedString(value: string | undefined) {
      const lowerString = value ? value.toLowerCase() : "";
      return removeSpecialCharacters(lowerString);
    }

    const isSpotsEmpty = computed(() => {
      return (
        currentSpotsGroup.value.filter((group) => group.spots.length > 0)
          .length === 0
      );
    });

    watch(
      () => props.outletId,
      () => {
        getSpots();
        listenFromSpotOccupancy(
          props.outletId,
          ["table-occupancy.occupied", "table-occupancy.available"],
          getSpotsOccupied
        );
        listenFromSpotOccupancy(
          props.outletId,
          [
            "table.reserved",
            "table.reservation-moved",
            "table.reservation-deleted",
          ],
          handleReservations
        );
      },
      { immediate: true }
    );

    onBeforeUnmount(() => {
      unlistenFromSpotOccupancy(
        props.outletId,
        ["table-occupancy.occupied", "table-occupancy.available"],
        getSpotsOccupied
      );
      unlistenFromSpotOccupancy(
        props.outletId,
        [
          "table.reserved",
          "table.reservation-moved",
          "table.reservation-deleted",
        ],
        handleReservations
      );
    });

    async function handleReservations() {
      await Promise.all([getSpotsReservations(), getReservationsWithoutSpot()]);
    }

    function setDayFilter(setDay: number) {
      dayFilter.value = dayjs(dayFilter.value).add(setDay, "day");
      handleSpots();
    }

    function setOnlyAvailable() {
      onlyAvailable.value = !onlyAvailable.value;
    }

    function setSearch(value: string) {
      searchQuery.value = value;
    }

    const spotsResponse = ref<IFetchSpotsResponse>([]);

    async function getSpots() {
      isLoading.value = true;
      try {
        spotsResponse.value = await fetchSpots({ outletId: props.outletId });

        if (!spotsResponse.value) return;

        spotsResponse.value.sort((a, b) => {
          const lastAlphabetWord = "zzzz";
          return (
            (a.groupingLabel ?? lastAlphabetWord).localeCompare(
              b.groupingLabel ?? lastAlphabetWord,
              "en",
              {
                numeric: true,
              }
            ) ||
            a.name.localeCompare(b.name, "en", {
              numeric: true,
            })
          );
        });

        await handleSpots();
      } catch (error) {
        report(error);
      } finally {
        isLoading.value = false;
      }
    }

    const spotsReservations = ref<IFetchSpotsReservationsResponse>([]);

    async function getSpotsReservations() {
      try {
        spotsReservations.value = await fetchSpotsReservations({
          outletId: props.outletId,
          startingAt: startingAtFilter.value,
          endingAt: endingAtFilter.value,
        });
      } catch (error) {
        report(error);
      }
    }

    const spotsOccupied = ref<IFetchSpotsOccupiedResponse>([]);

    async function getSpotsOccupied() {
      try {
        spotsOccupied.value = await fetchSpotsOccupied({
          outletId: props.outletId,
          startingAt: startingAtFilter.value,
          endingAt: endingAtFilter.value,
        });
      } catch (error) {
        report(error);
      }
    }

    async function handleSpots() {
      await Promise.all([
        getSpotsOccupied(),
        getSpotsReservations(),
        getReservationsWithoutSpot(),
      ]);
    }

    function findOccupiedAt(spotId: string) {
      const findSpot = spotsOccupied.value.find(
        (spot) => spot.spotId === spotId && spot.status === "occupied"
      );

      return findSpot?.occupiedAt ?? undefined;
    }

    const reservationsWithoutSpot = ref<IFetchReservationsWithoutSpotResponse>(
      []
    );

    async function getReservationsWithoutSpot() {
      try {
        reservationsWithoutSpot.value = await fetchReservationsWithoutSpot({
          outletId: props.outletId,
          startDate: startingAtFilter.value,
          endDate: endingAtFilter.value,
        });
      } catch (error) {
        report(error);
      }
    }

    return {
      t,
      css,
      handleSpots,
      isLoading,
      spotsGroup,
      spotsResponse,
      collapsedAll,
      onlyAvailable,
      dayFilter,
      spotsOccupied,
      setDayFilter,
      setOnlyAvailable,
      setSearch,
      isSpotsEmpty,
      currentSpotsGroup,
      reservationsWithoutSpot,
      AtomTextTagEnum,
      AtomTextTypeEnum,
      AtomTextColorEnum,
    };
  },
});
