import {
  IMoveSpotReservationRequest,
  IMoveSpotReservationBodyParams,
} from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class MoveSpotReservationError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: MoveSpotReservation");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function moveSpotReservation(
  request: IMoveSpotReservationRequest
): Promise<boolean> {
  let response: AxiosResponse;

  try {
    const params: IMoveSpotReservationBodyParams = {
      table_id: request.spotId,
    };

    response = await http.put(
      `api/v3/outlets/${request.outletId}/tables/reservations/${request.reservationId}/move-to-table`,
      { ...params }
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    const customError = e?.response?.data?.error;
    if (customError) {
      e.errorCode = customError?.code;
    }

    throw new MoveSpotReservationError(e);
  }

  return Boolean(response);
}
