import { IMarkOccupiedSpotRequest } from "./types";
import { IRepoErrors } from "@/v2/repo/errors";
import { AxiosResponse } from "axios";
import { http } from "../../http";

export class MarkOccupiedSpotError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: MarkOccupiedSpot");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function markOccupiedSpot(
  request: IMarkOccupiedSpotRequest
): Promise<boolean> {
  let response: AxiosResponse;

  try {
    response = await http.put(
      `api/v3/outlets/${request.outletId}/tables/${request.spotId}/mark-as-occupied`
    );
  } catch (e: any) {
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new MarkOccupiedSpotError(e);
  }

  return Boolean(response);
}
