import { http } from "../../http";
import {
  IFetchSpotsOccupiedRequest,
  IFetchSpotsOccupiedContract,
  IFetchSpotsOccupiedResponse,
} from "./types";
import { AxiosResponse } from "axios";
import { IRepoErrors } from "@/v2/repo/errors";

export class FetchSpotsOccupiedError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: FetchSpotsOccupied");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}
export async function fetchSpotsOccupied(
  request: IFetchSpotsOccupiedRequest
): Promise<IFetchSpotsOccupiedResponse> {
  let response: AxiosResponse<IFetchSpotsOccupiedContract>;

  try {
    response = await http.get(
      `api/v3/outlets/${request.outletId}/tables/occupancies`,
      {
        params: {
          starting_at: request.startingAt,
          ending_at: request.endingAt,
        },
      }
    );
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new FetchSpotsOccupiedError(error);
  }

  const mapStatus: Record<number, "available" | "occupied"> = {
    1: "available",
    2: "occupied",
  };

  return response.data.data.length
    ? response.data.data.map((spot) => ({
        spotId: spot.table_id,
        occupiedAt: spot?.recorded_at ? new Date(spot.recorded_at) : undefined,
        status: mapStatus[spot.status],
      }))
    : [];
}
